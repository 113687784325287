import type { DiscoDetail, DiscoList } from './discoDetails'
import { discoDetails } from './discoDetails'

import type { BrazilianStatesKeys } from '~/constants/geo'

export const connectedDiscos = [
  {
    state: 'Distrito Federal',
    discos: 'Neoenergia',
  },
  {
    state: 'Goiás',
    discos: 'Equatorial',
  },
  {
    state: 'Minas Gerais',
    discos: 'Cemig',
  },
  {
    state: 'Rio de Janeiro',
    discos: 'Light',
  },
  {
    state: 'São Paulo',
    discos:
      'CPFL Paulista, CPFL Piratininga, CPFL Santa Cruz, Energisa e Elektro',
  },
  {
    state: 'Mato Grosso',
    discos: 'Energisa',
  },
  {
    state: 'Mato Grosso do Sul',
    discos: 'Elektro, Energisa',
  },
  {
    state: 'Ceará',
    discos: 'Enel CE',
  },
  {
    state: 'Pernambuco',
    discos: 'Neoenergia',
  },
  {
    state: 'Alagoas',
    discos: 'Equatorial',
  },
  {
    state: 'Piauí',
    discos: 'Equatorial',
  },
  {
    state: 'Sergipe',
    discos: 'Energisa',
  },
  {
    state: 'Bahia',
    discos: 'Neoenergia Coelba',
  },
  {
    state: 'Paraná',
    discos: 'Copel',
  },
]

export const futureDiscos = [
  {
    state: 'Rio de Janeiro',
    discos: 'Enel RJ',
  },
  {
    state: 'Rio Grande do Sul',
    discos: 'RGE',
  },
  {
    state: 'São Paulo',
    discos: 'EDP SP',
  },
]

type FullDiscos = {
  [key in BrazilianStatesKeys]: { label: string; value: DiscoList }[]
}

const hasDisplayName = (disco: DiscoDetail): disco is Required<DiscoDetail> =>
  !!disco.displayName

export const allDiscosByStates = discoDetails
  // not having a displayName means it's been renamed or is not active
  .filter(hasDisplayName)
  .filter(disco => disco.code !== 'celpe')
  .reduce((discosByStates, disco) => {
    for (const state of disco.states) {
      discosByStates[state] = discosByStates[state] || []
      discosByStates[state].push({
        label: disco.displayName,
        value: disco.code,
      })
    }
    return discosByStates
  }, {} as FullDiscos)

export const labelByDisco = Object.values(allDiscosByStates).reduce(
  (acc, discos) => {
    discos.forEach(disco => (acc[disco.value] = disco.label))

    return acc
  },
  {} as { [key in DiscoList]: string },
)
